<div class="text-center d-flex flex-column" style="border-radius: 30px">
  <div class="px-5 py-3">
    <img src="{{
        successful ? 'assets/svg/success.svg' : 'assets/svg/failure.svg'
      }}" height="115" class="mt-3 mb-4" />
    <h1 class="mb-3">
      {{ title }}
    </h1>
    <span class="medium text-size-15px">
      {{ content }}
    </span>
    <div class="mb-4 mt-5">
      <button type="button" class="btn px-5" (click)="close()" [ngClass]="{
          'btn-primary': !successful,
          'btn-dark': successful
        }">
        <span class="mx-4">Aceptar</span>
      </button>
    </div>
  </div>
</div>
<div class="my-3 position-absolute close-btn mx-3">
  <a [routerLink]="" class="text-dark" (click)="close()">
    <i class="bi bi-x-lg"></i>
  </a>
</div>
