import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from './services/auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'aramark';
  idleState = 'NOT_STARTED';
  countdown?: number;
  lastPing?: Date;

  constructor(
    private router: Router,
    private authSvc: AuthService,
    private permissionsService: NgxPermissionsService,
    private idle: Idle,
    cd: ChangeDetectorRef
  ) {
    const IDLE_TIME = 5;
    const INACTIVE_TIME = 300;
    idle.setIdle(IDLE_TIME); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(INACTIVE_TIME); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
    });
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';

      this.countdown = undefined;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';

      this.authSvc.logout();
      this.router.navigate(['/auth']);
      window.location.reload();
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe((seconds) => (this.countdown = seconds));

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar

        this.reset();
      }
    });
  }
  ngOnInit(): void {
    this.authSvc.refreshSession();
    const token = localStorage.getItem('token');
    if (!token) {
      this.authSvc.logout();
      //this.router.navigate(['/auth']);
    }

    let rol = localStorage.getItem('role');
    if (rol) {
      this.permissionsService.loadPermissions([rol]);
    }
    this.reset();
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = undefined;
    this.lastPing = undefined;
  }
}
