import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { MockDataService } from './mock-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private mockDataSvc: MockDataService,
    private permissionsService: NgxPermissionsService,
    private http: HttpService
  ) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post('/auth/login', { email, password });
  }

  selectContext(id: string): Observable<any> {
    return this.http.post('/auth/select-context', { roleId: id });
  }

  logout() {
    localStorage.removeItem('user');
    this.permissionsService.flushPermissions();
  }

  refreshSession() {
    const role = localStorage.getItem('role');
    if (role) {
      this.permissionsService.loadPermissions([role]);
    }
  }

  getTermsAndConditions() {
    return this.http.get(`/gateway/terms-conditions`);
  }

  acceptTermAndConditions(userId: string) {
    return this.http.post(`/auth/termCondition?userId=${userId}`);
  }

  editTermsAndConditions(newText: string) {
    return this.http.put(`/terms-conditions`, {
      term: newText,
    });
  }
  verifyCode(code: number): Observable<any> {
    return this.http.post('/auth/verify/code', { code: code });
  }
  createCompany(data: any): Observable<any> {
    return this.http.post('/auth/company/create', data);
  }
  createUserCompany(data: any, id: string): Observable<any> {
    return this.http.post(`/auth/company/user/create?companyId=${id}`, data);
  }
}
