import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ErrorHandlerInterceptor } from './shared/interceptors/error.interceptor';
import { NgChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DataTablesModule } from 'angular-datatables';
import { QRCodeModule } from 'angularx-qrcode';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { es } from 'date-fns/locale';
import { DecimalPipe } from '@angular/common';
import localesEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import {
  NgxLoadingModule,
  ngxLoadingAnimationTypes,
  NgxLoadingService,
} from 'ngx-loading';
import { NgIdleModule } from '@ng-idle/core';
import { QuillModule } from 'ngx-quill';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localesEs, 'es_CL');
const espConfing = new DateFnsConfigurationService();
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    DateFnsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    NgChartsModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 1,
      backgroundStrokeWidth: 0,
      space: -11,
      maxPercent: 100,
      outerStrokeWidth: 11,
      outerStrokeColor: '#C6C6FD',
      innerStrokeColor: '#F1F1F1',
      innerStrokeWidth: 11,
      showSubtitle: false,
    }),
    DataTablesModule,
    QRCodeModule,
    RouterModule,
    NgIdleModule.forRoot(),
    QuillModule.forRoot(),
    HttpClientJsonpModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es_CL',
    },
    {
      provide: DateFnsConfigurationService,
      useValue: espConfing,
    },
    DecimalPipe,
    NavigationService,
    NgxLoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
