import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  get(endpoint: string, params: any = {}) {
    return this.http.get<any>(this.apiUrl + endpoint, { params });
  }

  post(endpoint: string, data: any = {}) {
    return this.http.post<any>(this.apiUrl + endpoint, data);
  }

  put(endpoint: string, data: any = {}) {
    return this.http.put<any>(this.apiUrl + endpoint, data);
  }

  patch(endpoint: string, data: any = {}) {
    return this.http.patch<any>(this.apiUrl + endpoint, data);
  }

  delete(endpoint: string, params: any = {}) {
    return this.http.delete<any>(this.apiUrl + endpoint, { params });
  }

  async getWithBody(endpoint: string, body: any = {}) {
    return await fetch(this.apiUrl + endpoint, { body: body });
  }

  postWithHeader(endpoint: string, body: any = {}, header: any = {}) {
    return this.http.post<any>(this.apiUrl + endpoint, body, { headers: header });
  }
}
