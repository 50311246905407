import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  catchError,
  retry,
  delay,
  retryWhen,
  take,
  concat,
  tap,
  map,
} from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OperationFinishedModalComponent } from '../modals/operation-finished-modal/operation-finished-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private modalSvc: BsModalService, private authSvc: AuthService, private router: Router,) {
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => this.errorHandler(error))
    );
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    let content = '';
    if ((error.status === 401 || error.status === 403) && !this.router.url.includes('/auth')) {
      this.logOut()
      return throwError('Error personalizado');
    }
    else {
      if (error.status >= 400 && error.status < 500) {
        content = error.error.error.message;
      }
      if (error.status == 404 && error.error.path.includes('companyYield')) {
        return throwError('Error personalizado');
      }
      const modalRef = this.modalSvc.show(OperationFinishedModalComponent, {
        initialState: {
          title: content || 'Algo paso vuelve a intentarlo',
          content: '',
          successful: false,
        },
      });
      return throwError({ modalRef, error });
    }
  }

  logOut() {
    this.authSvc.logout();
    this.router.navigate(['/auth']);
  }
}
