import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MockDataService {
  users = [
    {
      email: 'superadmin@aramark.cl',
      password: '123456',
      role: 'SUPERADMIN',
    },
    {
      email: 'admin@aramark.cl',
      password: '123456',
      role: 'ADMIN',
    },
    {
      email: 'contratista@aramark.cl',
      password: '123456',
      role: 'CONTRACTOR',
    },
  ];

  constructor() {}
}
